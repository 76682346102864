import React from 'react';
import styles from "./Slide.module.css";


const Slide1 = () => {
   
    return (
      <div>
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />   
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />   
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />
        <img className={styles.fallingImage} src="/image/character/cutereon.png" alt="Cutereon character" />

       
      </div>
    );
};

export default Slide1
