import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel ,Navigation,Pagination} from 'swiper/modules';
import './ThirdSlide.css'; 
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/mousewheel';
import { FaGithub } from 'react-icons/fa';
const projects = [
    
        {
          "id": 0,
          "name": "Reon",
          "description": "AI를 통한 연기연습 플렛폼",
          "content": [
            "Frontend 아키텍처 설계 및 주요 UI 컴포넌트, UX 수정",
            "표준 라이브러리에 의존하지 않고 SVG를 활용하여 반응형 차트 컴포넌트를 독자적으로 구현, 성능 최적화 및 유지보수의 용이성 확보",
            "UI 컴포넌트를 제작하여 디자인 시스템에 통합, Jira 이용한 문서화로 개발 효율성 증대",
            "custom hooks를 개발하여 복잡한 사용자 인터랙션을 손쉽게 관리",
            "게시판 클릭과정 재생의 버그를 수정, 성능 및 안정성 향상"
          ],
          "repoUrl": "https://github.com/non-inss/REON",
          "image": './reon.png',
          "period": ["2023. 07", "2023. 08"],
          "stack": [
            <img src="https://img.shields.io/badge/React-61DAFB?style=flat-square&logo=react&logoColor=white" alt="React" />,
            <img src="https://img.shields.io/badge/JavaScript-F7DF1E?style=flat-square&logo=javascript&logoColor=black" alt="JavaScript" />,
            <img src="https://img.shields.io/badge/Tailwind CSS-06B6D4?style=flat-square&logo=Tailwind CSS&logoColor=white" alt="Tailwind CSS"/>
           
          ]
        },
        {
          "id": 1,
          "name": "Kkori",
          "description": "ioT를 이용한 강아지 산책대행 플렛폼",
          "content": [
            "전체 UI/UX 디자인, 효율적인 네비게이션 구조 설계로 사용자 경험 극대화",
            "Firebase 실시간 데이터베이스를 활용하여 실시간 채팅 기능 구현",
            "프론트엔드 아키텍처 설계 및 상태 관리 로직 구현을 통해 애플리케이션의 반응 속도와 성능 최적화",
            "앱 빌드 및 APK 배포",
            "팀 내 협업 효율성을 증진시키기 위해 Git,Jira 컨벤션 작성",
          ],
          "repoUrl": "https://github.com/non-inss/KKORI-SSAFY",
          "image": './kkori.png',
          "period": ["2023. 10", "2023. 11"],
          "stack": [
            <img src="https://img.shields.io/badge/React Native-61DAFB?style=flat-square&logo=React&logoColor=white" alt="React Native"/>,
            <img src="https://img.shields.io/badge/JavaScript-F7DF1E?style=flat-square&logo=javascript&logoColor=black" alt="JavaScript" />,
            <img src="https://img.shields.io/badge/Expo-000000?style=flat-square&logo=Expo&logoColor=white" alt="Expo"/>,
            <img src="https://img.shields.io/badge/firebase-FFCA28?style=flat-square&logo=firebase&logoColor=white" alt="Firebase"/>
          ]
        },
        {
          "id": 2,
          "name": "Rualone",
          "description": "공공데이터 기반 여행동행 사이트",
          "content": [
            "Vuex를 사용하여 동적인 사용자 인터페이스 구축 및 상태 관리 체계 확립",
            "동행 구하기 기능을 통해 사용자 간 쉬운 매칭과 여행 계획 공유 가능",
            "서버사이드 렌더링(SSR) 문제를 해결하여 SEO 최적화 및 페이지 로딩 속도 개선",
            "사용자 피드백을 기반으로 UI/UX 개선안을 도출 및 신속한 반영을 통해 서비스 개선 지속",
          ],
          "repoUrl": "https://github.com/Lets-Travel-Well",
          "image": './rualone.png',
          "period": ["2023. 06", "2023. 07"],
          "stack": [
          <img src="https://img.shields.io/badge/Vue.js-4FC08D?style=flat-square&logo=Vue.js&logoColor=white" alt="Vue.js"/>,
          <img src="https://img.shields.io/badge/Bootstrapap-7952B3?style=flat-square&logo=bootstrap&logoColor=white" alt="boot"/>,
          ]
        },
        {
          "id": 3,
          "name": "뱅크넛",
          "description": "얼굴인식을 통한 ATM인증 시스템",
          "content": [
            "인프라 및 배포 담당",
            "Nginx를 활용한 리버스 프록시 서버 구축으로 보안성 향상 및 로드 밸런싱 최적화",
            "LDAP 서버 배포를 통한 팀원 간 효율적인 코드 리뷰 및 액세스 관리",
            "Jenkins와 스크립트를 사용하여 CI/CD 파이프라인 자동화, 배포 과정의 오류 최소화",
            "Ubuntu 서버 운영을 통해 시스템의 안정성 및 보안성 검증",
            "로깅 시스템을 통합하여 시스템 모니터링 및 예측 가능한 서비스 중단 사전 방지",
          ],
          "repoUrl": "https://github.com/non-inss",
          "image": './bank.png',
          "period": ["2022. 08", "2022. 10"],
          "stack": [<img src="https://img.shields.io/badge/Amazon AWS-232F3E?style=flat-square&logo=amazonaws&logoColor=white" alt="Amazon AWS"/>,
          <img src="https://img.shields.io/badge/Docker-2496ED?style=flat-square&logo=Docker&logoColor=white" alt="Docker"/>,
          <img src="https://img.shields.io/badge/Ubuntu-E95420?style=flat-square&logo=Ubuntu&logoColor=white" alt="Ubuntu"/>,
          <img src="https://img.shields.io/badge/nginx-%23009639.svg?style=flat-square&logo=nginx&logoColor=white" alt="Nginx"/>]
        },
        {
          "id": 4,
          "name": "Hive",
          "description": "오픈API를 활용한 영화추천 사이트",
          "content": [
            "사용자 중심의 인터랙티브한 웹 인터페이스 설계 및 구현",
            "대량의 영화 데이터 처리와 동시에 빠른 페이지 로딩 속도 유지를 위해 비동기 처리",
            "로딩 시간 단축을 위한 화면설계 ",
            "데이터 가중치 알고리즘을 통해 사용자에게 영화추천 ",
          ],
          "repoUrl": "https://github.com/non-inss",
          "image": './hive.png',
          "period": ["2023. 05", "2023. 05"],
          "stack": [ <img src="https://img.shields.io/badge/Vue.js-4FC08D?style=flat-square&logo=Vue.js&logoColor=white" alt="Vue.js"/>,
          <img src="https://img.shields.io/badge/Bootstrapap-7952B3?style=flat-square&logo=bootstrap&logoColor=white" alt="boot"/>,]
        }
      
];
// 프로젝트를 4개씩 그룹화하는 함수
function chunkProjects(projects, size) {
    const chunks = [];
    for (let i = 0; i < projects.length; i += size) {
      chunks.push(projects.slice(i, i + size));
    }
    return chunks;
  }
  
  const projectChunks = chunkProjects(projects, 4);
export default function ThirdSlide() {
  return (
    <div className="third-slide-container">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        navigation={true}
        pagination={{
            dynamicBullets: true,
          }}
        modules={[Mousewheel, Navigation,Pagination]}
     
        className="outer-swiper"
      >
        {projectChunks.map((chunk, chunkIndex) => (
          <SwiperSlide key={chunkIndex}>
            <div className="project-grid">
              {chunk.map(project => (
            
                <div className="project-card" key={project.id}>
                   <div className={`project-image-container ${project.name === 'Reon' ? 'reon-image' : ''}`}>
                    <div className="project-image">
                      <img src={project.image} alt={project.name} />
                    </div>
                    <h3>{project.name}</h3>
                    <span>기간: {project.period.join(' - ')}</span>
                    <div className="project-links">
                <a href={project.repoUrl} target="_blank" rel="noopener noreferrer">
                  <FaGithub /> {/* 깃허브 아이콘 사용 */}
                </a>
              </div>
                  </div>
                  <div className="project-info">
                    <h3>{project.description}</h3>
                    <ul className="project-content-list">
                        {project.content.map((item, index) => (
                        <li key={index}>{item}</li>
                        ))}
                    </ul>
                    <ul className="project-stack">
                      {project.stack.map((tech, techIndex) => (
                        <li key={techIndex}>{tech}</li>
                        
                      ))}
                    </ul>
                  </div>
                </div>
                
   
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
