import React, { useState } from 'react';
import './NavBar.css';

function Navbar({ activeIndex, goToSlide, className }) {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-theme');
  };
  
  return (
    <div className={`navbar ${className}`}>
      {['Main', 'About', 'Portfolio', 'Contact'].map((slide, index) => (
        <button
          key={slide}
          className={`nav-item ${activeIndex === index ? 'active' : ''}`}
          onClick={() => goToSlide(index)}
        >
          {slide}
        </button>
      ))}
      <button className={`nav-item theme-toggle ${isDarkMode ? 'moon' : 'sun'}`} onClick={toggleTheme}>
        {isDarkMode ? '🌙' : '☀️'}
      </button>
    </div>
  );
}

export default Navbar;
