import React from 'react';
import { FaGithub, FaYoutube, FaInstagram } from 'react-icons/fa';
import './Last.css';

function Last() {
  const handleSubmit = (event) => {
    event.preventDefault();
    // 폼 제출 로직 (예: 이메일 전송 로직)
  };

  return (
    <div className="last-container">
      <h2>Contact Me</h2>
      <form onSubmit={handleSubmit}>
        <input type="email" placeholder="Your Email" required />
        <textarea placeholder="Message" required></textarea>
        <button type="submit">Send Message</button>
      </form>
      <div className="social-links">
        <a href="https://github.com/non-inss" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
        <a href="https://www.youtube.com/channel/UCD3MjATx3ctw4uqLaYq92iA" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
        <a href="https://www.instagram.com/non_inss/reels/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
      </div>
    </div>
  );
}

export default Last;
