import styles from "./Test.module.css";

const Test = () => {
 
  return (

    
    <div className={styles.testContainer}>
      <h1 className={styles.testTitle}>
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br/>
          <br/>
          <br/>
          <span className={styles.testTitleSpan}>L</span>
          <span className={styles.testTitleSpan}>e</span>
          <span className={styles.testTitleSpan}>e</span>
          <span className={styles.testTitleSpan}>M</span>
          <span className={styles.testTitleSpan}>y</span>
          <span className={styles.testTitleSpan}>e</span>
          <span className={styles.testTitleSpan}>o</span>
          <span className={styles.testTitleSpan}>n</span>
          <span className={styles.testTitleSpan}>g</span>
          <span className={styles.testTitleSpan}>I</span>
          <span className={styles.testTitleSpan}>n</span>
          {/* <span className={styles.testTitleSpan}>N</span> */}
          <br />
          <span className={styles.testTitleSpan}>L</span>
          <span className={styles.testTitleSpan}>e</span>
          <span className={styles.testTitleSpan}>t</span>
          <span className={styles.testTitleSpan}>'</span>
          
          <span className={styles.testTitleSpan}>s&nbsp;</span>
          <span className={styles.testTitleSpan}>G</span>
          <span className={styles.testTitleSpan}>o</span>
          <span className={styles.testTitleSpan}>!</span>
          <div className="flex justify-center"> 

         
            
       
          </div>
      </h1>
    </div>
    
  );
}

export default Test;
