import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Mousewheel, Pagination,Navigation} from 'swiper/modules';
import './MainPage.css'
import Navbar from './NavBar';
import SecondSlide from './SecondSlide';
import ThirdSlide from './ThirdSlide';
import Last from './Last';

import Test from '../components/home/Test'
import Curtain from '../components/home/Curtain'
import Slide1 from '../components/home/Slide1'

export default function MainPage() {
  // const lightBlue = '#8ccfd5';
  // const beige = '#f2ecda';
  // const middlecolor = '#b6e3e4';
  const backcolor2 = '#D6D6C9';
  useEffect(() => {
    const bubbleContainer = document.querySelector('.bubble-container');

    function createBubble() {
      const bubble = document.createElement('div');
      bubble.classList.add('bubble');
      bubble.style.left = `${Math.random() * 100}%`;
      bubble.style.width = `${Math.random() * 30 + 30}px`;
      bubble.style.height = bubble.style.width;
      bubble.style.animationDuration = `${Math.random() * 3 + 2}s`;
      bubbleContainer.appendChild(bubble);

      setTimeout(() => {
        bubble.remove();
      }, 5000);
    }

    const interval = setInterval(createBubble, 700);

    return () => clearInterval(interval);
  }, []);

  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const toggleTheme = () => {
    document.body.classList.toggle('dark-theme');
  };
  const goToSecondSlide = () => {
    if (swiper) {
      swiper.slideTo(1); // 0-indexed, 1은 두 번째 슬라이드를 의미
    }
  };
  const goToSlide = (index) => {
      if (swiper) {
        swiper.slideTo(index);
      }
    };

    useEffect(() => {
      if (swiper) {
        const changeHandler = () => {
          setActiveIndex(swiper.activeIndex);
    
          const profileSection = document.querySelector('.animate-profile');
          const skillsSection = document.querySelector('.animate-skills');
    
          // 두 번째 슬라이드(SecondSlide)일 때
          if (swiper.activeIndex === 1) {
            profileSection?.classList.add('active');
            skillsSection?.classList.add('active');
          } else {
            profileSection?.classList.remove('active');
            skillsSection?.classList.remove('active');
          }
        };
    
        swiper.on('slideChange', changeHandler);
        return () => {
          swiper.off('slideChange', changeHandler);
        };
      }
    }, [swiper]);
    
    
  return (
    <>
    <div className="bubble-container"></div>
    
   <Navbar activeIndex={activeIndex} goToSlide={goToSlide} className={activeIndex > 0 ? 'visible' : ''}toggleTheme={toggleTheme} />

    <Swiper
      direction={'vertical'}
      slidesPerView={1}
      speed={1500}
      mousewheel={{
        sensitivity: 0.5,
        releaseOnEdges: true
      }}
      navigation={false}
      pagination={false}
      style={{ height: '100vh' }}
      modules={[Mousewheel, Pagination, Navigation]}
      onSwiper={setSwiper}
    >
    



    <SwiperSlide style={{ position: 'relative', overflow: 'hidden', background: backcolor2}}>
       <Curtain/>
                <Slide1/>
                <Test/>        
        
        <div style={{ 
          position: 'absolute', 
          top: 0, 
          left: 0, 
          right: 0, 
          bottom: 0, 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          zIndex: 1,
          flexDirection: 'column',
        }}>
          <div className='bor'>
          <div>
            <h3 className='title'>Front-end developer</h3>
          </div>
          <div>
            <button onClick={goToSecondSlide} className="view-work-button">
              화면에 마우스를 올려주세요
            </button>
          </div>
          <div className="scroll-down">
          👀Scroll Down👀
          </div>
          </div>
        </div>
        
      </SwiperSlide>
      <SwiperSlide>

        <SecondSlide></SecondSlide>
      </SwiperSlide>
      <SwiperSlide >
        <ThirdSlide></ThirdSlide>
      </SwiperSlide>
      <SwiperSlide>
        <Last></Last>
      </SwiperSlide>
    </Swiper>
    </>
  );
}
