import React from 'react';
import './SecondSlide.css';
import { useLottie } from "lottie-react";
import animationData from './acc.json';

function SecondSlide() {

  const options = {
    animationData: animationData,
    loop: true
  };
  const { View } = useLottie(options);
  
  return (
    <div className="second-slide-container">
      {/* Profile Section */}
      
      <div className="profile-section animate-profile">
        <img src="/inssprofile.jpg" alt="Profile" className="profile-image" />
        <div className="career-awards">
        <p>안녕하세요😊 프론트엔드 개발자 이명인입니다.<br/>
        저는 UI 효과, 애니메이션 및 직관적이고 역동적인 사용자 경험을 만드는데 관심이 많습니다.</p>
          <h6>🏃🏻‍♂️ 약 력</h6>
          <ul style={{fontWeight:"600"}}>
            <li>마음AI(웹개발) (2024-)</li>
            <li>삼성소프트웨어 아카데미(SSFAY) (2023-2024)</li>
            <li>KORAD 인턴 (2020-2020)</li>
            <li>한국전력공사 인턴 (2020-2020)</li>
          </ul>
          <h6>🏆 수상 이력</h6>
          <ul>
            <li style={{fontWeight:"600"}}>인천광역시 공공데이터 활용 경진대회 수상(2023)</li>
          </ul>
          <h6>👓 좋아하는 것</h6>
          <ul>
            <li style={{fontWeight:"600"}}>별보기, 등산, 혼자여행하기</li>
          </ul>
        </div>
 
      </div>

      {/* Skills Section */}
      <div className="skills-section animate-skills">
        <h2>Skills</h2>

        {/* Language/Framework/Library */}
        <div className="skill-category">
          <h3>📘 Language/Framework/Library</h3>
          <div className="skill-level">
            <h4>편해요</h4>
            <img src="https://img.shields.io/badge/Python-3776AB?style=for-the-badge&logo=Python&logoColor=white" alt="Python"/>
            <img src="https://img.shields.io/badge/JavaScript-F7DF1E?style=for-the-badge&logo=javascript&logoColor=black" alt="JavaScript" />
            <img src="https://img.shields.io/badge/React-61DAFB?style=for-the-badge&logo=react&logoColor=white" alt="React" />
            <img src="https://img.shields.io/badge/React Native-61DAFB?style=for-the-badge&logo=React&logoColor=white" alt="React Native"/>
            <img src="https://img.shields.io/badge/Tailwind CSS-06B6D4?style=for-the-badge&logo=Tailwind CSS&logoColor=white" alt="Tailwind CSS"/>
          </div>
          <div className="skill-level">
            <h4>어느정도 사용할 수 있어요</h4>
            <img src="https://img.shields.io/badge/Vue.js-4FC08D?style=for-the-badge&logo=Vue.js&logoColor=white" alt="Vue.js"/>
            <img src="https://img.shields.io/badge/django-092E20?style=for-the-badge&logo=django&logoColor=white" alt="Django"/>
            <img src="https://img.shields.io/badge/Expo-000000?style=for-the-badge&logo=Expo&logoColor=white" alt="Expo"/>
          </div>
        </div>

        {/* Tools/Analytics */}
        <div className="skill-category">
          <h3>🔨 Tools/Analytics</h3>
          <div className="skill-level">
            <h4>편해요</h4>
            <img src="https://img.shields.io/badge/Git-F05032?style=for-the-badge&logo=git&logoColor=white" alt="Git" />
            <img src="https://img.shields.io/badge/Postman-FF6C37?style=for-the-badge&logo=Postman&logoColor=white" alt="Postman"/>
            <img src="https://img.shields.io/badge/Figma-F24E1E?style=for-the-badge&logo=figma&logoColor=white" alt="Figma" />
            <img src="https://img.shields.io/badge/Notion-F3F3F3.svg?style=for-the-badge&logo=notion&logoColor=black" alt="Notion" />
            <img src="https://img.shields.io/badge/Jira-0052CC?style=for-the-badge&logo=jira&logoColor=white" alt="Jira" />

          </div>
          <div className="skill-level">
            <h4>어느정도 사용할 수 있어요</h4>
            <img src="https://img.shields.io/badge/firebase-FFCA28?style=for-the-badge&logo=firebase&logoColor=white" alt="Firebase"/>
            <img src="https://img.shields.io/badge/Amazon AWS-232F3E?style=for-the-badge&logo=amazonaws&logoColor=white" alt="Amazon AWS"/>
            <img src="https://img.shields.io/badge/Docker-2496ED?style=for-the-badge&logo=Docker&logoColor=white" alt="Docker"/>
            <img src="https://img.shields.io/badge/Ubuntu-E95420?style=for-the-badge&logo=Ubuntu&logoColor=white" alt="Ubuntu"/>
            <img src="https://img.shields.io/badge/nginx-%23009639.svg?style=for-the-badge&logo=nginx&logoColor=white" alt="Nginx"/>
           
            <div style={{width:'30%', height:'30%'}}>

              {View}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default SecondSlide;
